

import { Vue, Component, Watch } from 'vue-property-decorator'
import { ProjectDetail } from '@/types/onlineStatistics'
import { Page } from '@/types/common'
import { dateBefore } from '@/utils/formatDate'

@Component
export default class OnlineStatistics extends Vue {
  page = 1
  size = 10
  total = 0
  loading = false
  tableData: ProjectDetail[] = []
  searchForm = {
    projectId: '',
    startTime: '',
    endTime: ''
  }

  pickMinTime: any = null
  dateRange: Array<string> = []
  @Watch('dateRange')
  dateRangeChange (value: string[]) {
    value = value || []
    this.searchForm.startTime = value[0]
    this.searchForm.endTime = value[1]
  }

  get projectList () {
    return this.$store.state.projectList
  }

  // 日期选择当天之前
  pickerOptions: any = {
    onPick: (date: {maxDate: string; minDate: any}) => {
      this.pickMinTime = date.minDate.getTime()
    },
    // 限制范围最大一个月且在今天之前的日期
    disabledDate: (time: any) => {
      if (this.pickMinTime) {
        const one = 30 * 24 * 3600 * 1000
        const minTime: number = this.pickMinTime - one
        const maxTime: number = this.pickMinTime + one
        return time.getTime() > (Date.now() - 24 * 60 * 60 * 1000) || (time.getTime() < minTime || time.getTime() > maxTime)
      } else {
        return time.getTime() > new Date().getTime() - 24 * 60 * 60 * 1000
      }
    }
  }

  initTime () {
    this.dateRange = dateBefore(30, false)
    this.searchForm.startTime = this.dateRange[0]
    this.searchForm.endTime = this.dateRange[1]
  }

  created () {
    this.initTime()
    this.loadData()
  }

  // 绑定后重新获取数据
  activated () {
    this.loadData()
  }

  loadData () {
    this.loading = true
    this.$axios.get<Page<ProjectDetail>>(this.$apis.recruitUse.selectYhDutyCount, {
      ...this.searchForm,
      page: this.page,
      size: this.size
    }).then(res => {
      this.tableData = res.list
      this.total = res.total
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  onDetail (dutyId: string) {
    this.$router.push('/recruitUse/onlineStatistics/detail?dutyId=' + dutyId)
  }

  onSetting () {
    this.$router.push({
      name: 'rulesettings'
    })
  }

  onExport () {
    const loading = this.$loading({
      lock: true,
      text: '下载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadFile(this.$apis.recruitUse.exportWorkerDutyCount, this.searchForm).finally(() => {
      loading.close()
    })
  }

  getState (state: string) {
    const arr = ['', '未开工', '在建', '已移交']
    return arr[Number(state)]
  }

  getdutyRatio (data: string) {
    if (data.split('.')[1] && data.split('.')[1] !== '0') {
      return data + '%'
    } else {
      return data.split('.')[0] + '%'
    }
  }
}
